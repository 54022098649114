import React from 'react'
import './styles.scss'
import CardWithFlip from '../../Page/Industry/CardWithFlip'

function IndustryStatSection({ section }) {
  return (
    <div className="stat-section-wrapper">
        <section className='stat-section-container'>
            <h2 className='stat-header'>{section.header}</h2>
            <div className="card-container">
                {section.repeatcards?.map((card, index) => {
                return (
                    <CardWithFlip
                        number={card.statisticsectioncardheaderornumber}
                        frontDesc={card.statisticcarddescription}
                        backDesc={card.statisticcardbackdescription}
                        progressBar={card.statisticsectioncardheaderornumber}
                        key={index}
                     />
                )
                })}
            </div>
        </section>
     </div>  )
}

export default IndustryStatSection