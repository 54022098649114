import React, { useState } from 'react';
import './styles.scss'

const CardWithFlip = ({ number, backDesc, frontDesc, progressBar }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div 
      className={`stat-section-card ${isFlipped ? 'is-flipped' : ''}`}
      onClick={handleClick}
    >
      <img 
        className={`${isFlipped ? `stat-section-card-hover-icon-flipped` : `stat-section-card-hover-icon`}`}
        src='/images/swap-hover-icon-black.svg' 
        alt="Hover icon" 
      />
      <div className={`stat-section-card-number ${isFlipped ? `is-flipped` : ``}`}>{number}</div>
      <span className={`stat-section-card-desc ${isFlipped ? `is-flipped` : ``}`}>{isFlipped ? backDesc : frontDesc}</span>
      <div className={`${isFlipped ? `stat-section-progress-bar-flipped` : `stat-section-progress-bar`}`}>
        <div 
          className={`${isFlipped ? `stat-section-progress-flipped` : `stat-section-progress`}`} 
          style={{width: `${number}`}}
        />
      </div>
    </div>
  );
};

export default CardWithFlip;
