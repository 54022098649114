import React from 'react';
import './styles.scss'
const VideoSection = ({ section, videoSrc }) => {
  return (
    <div style={{position: 'relative', backgroundColor: section?.wrapperBackgroundColor || `#303d78`}}>
      <div className="industry-video-section-container">
        <div className="iframe-container">
          <iframe
            src={section?.video}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded Video"
            />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
