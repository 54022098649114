import React from 'react'
import './styles.scss'
import Carousel from 'react-multi-carousel';
import Arrow from '../../Arrow/Arrow';
import PlayButton from '../../PlayButton/PlayButton';


const responsiveCarousel = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 2561 },
    items: 5,
    slidesToSlide: 5
  },
  desktop: {
    breakpoint: { max: 2560, min: 1166 },
    items: 4,
    slidesToSlide: 4
  },
  tablet: {
    breakpoint: { max: 1165, min: 769 },
    items: 2,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

function IndustryCarousel({ section }) {
  return (
    <section className="carousel-section-wrapper">
    <div className='carousel-section-container'>
      <h2 className='carousel-section-header'>{section.carouselheader}</h2>
      <Carousel
          responsive={responsiveCarousel}
          ssr
          slidesToSlide={1}
          deviceType={''}
          containerClass="brands-page-wrapper"
          keyBoardControl
        >
        {section?.carouselcontent?.map?.((carousel, index) => (
          <div className='main-carousel-image-container'>
            {/* <h6
          className={`resources-section-card-category`}
          style={{
            backgroundColor: carousel?.cardcategorybackgroundcolor,
            color: carousel?.cardcategorytextcolor
          }}>{carousel?.cardcategory}
          </h6> */}
          <a href={carousel?.cardhref} target='_blank'>
            <div className="carousel-section-card">

            <div className="resources-section-card-transparent-layer" />
            <img className='carousel-section-image' src={carousel?.cardimage?.sourceUrl} />
            {carousel?.logoorheader === 'Logo' && carousel?.cardlogo?.sourceUrl && (<img className='carousel-section-logo' src={carousel?.cardlogo?.sourceUrl} />)}
            {carousel?.logoorheader === 'Header' && carousel?.cardheader && (<h5 className='carousel-section-card-header'>{carousel?.cardheader}</h5>)}
            {carousel?.arroworplay === 'arrow' ? (<Arrow className="resources-section-arrow" dark/>) : carousel.arroworplay === 'play' ? 
          (<PlayButton className="resources-section-play" />) : null}
          </div>
          </a>
          </div>
          ))}
      </Carousel>
    </div>
  </section>
   )
}

export default IndustryCarousel