import React from 'react'
import './styles.scss'
import {Carousel as ResponsiveCarousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from '../../Button/Button';
import PlayButton from '../../PlayButton/PlayButton';

const renderPrevButton = (clickHandler, hasPrev, label) => {
    return hasPrev && (
      <div
        className={"blocker-prevButton"}
        onClick={clickHandler}
      >
        <img alt="" className="img" src="/images/arrow.svg" />
      </div>
    )
  }
  const renderNextButton = (clickHandler, hasNext, label) => {
    return hasNext && (
      <div
        className={"blocker-nextButton"}
        onClick={clickHandler}
      >
        <img alt="" className="img" src="/images/arrow.svg" />
      </div>
    )
  }

function IndustryBlockerSection({ section }) {
  return (
    <section style={{backgroundColor: section.wrapperbackgroundcolor}} className='blocker-section-wrapper'>
    <div className="blocker-section-container">
    <h2 className='blocker-section-header'>{section.header}</h2>
    <ResponsiveCarousel
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      renderArrowPrev={renderPrevButton}
      renderArrowNext={renderNextButton}
  >
    {section?.blockercarouselfields?.map((blockerCarousel) => {

      return (
        <div className="blocker-carousel-inner-container">
          <div className="blocker-carousel-image-container">
          <img className='blocker-carousel-image' src={blockerCarousel.carouselimage.sourceUrl} />
          {blockerCarousel.videoorresourcelink === 'Video' && <PlayButton href={blockerCarousel.videohref} className='blocker-carousel-play' />}
          </div>
          <div className="blocker-carousel-logo-desc-container">
            <img className='blocker-carousel-logo' src={blockerCarousel.carousellogo.sourceUrl} />
            <h2 className='blocker-carousel-inner-header'>{blockerCarousel?.carouselheader}</h2>
            <span className='blocker-carousel-inner-desc'>{blockerCarousel.carouseldescription}</span>
            {blockerCarousel.videoorresourcelink === 'Video' && <span className='blocker-carousel-video-time'><PlayButton href={blockerCarousel.videohref} />{blockerCarousel.videotime}</span>}
            {blockerCarousel.videoorresourcelink === 'Link' && <Button className="blocker-carousel-resource-button" href={blockerCarousel.resourcelink}>{blockerCarousel.resourcebuttontext}</Button>}
          </div>
        </div>
      )
    })}
    </ResponsiveCarousel>
    </div>
  </section>  )
}

export default IndustryBlockerSection