import React from 'react';
import './styles.scss'
import Button from '../../Button/Button';

const IndustryImageDescription = ({ section, index }) => {
  return (
    <section className='industry-image-desc-container' key={index}>
      <div className='industry-desc-container'>
        <h6 className='industry-name'>{section.title}</h6>
        <h1 className='industry-main-header'>{section.header}</h1>
        <p className='industry-description'>{section.description}</p>
        {section.cta && <Button className="btn-fill">{section.cta}</Button>}
      </div>
      {section.image && section.image.sourceUrl != null && section.backgroundpatternimage && section.backgroundpatternimage.sourceUrl != null && (
        <>
          <img className='industry-image' src={section.image.sourceUrl} alt="Industry" />
          <img className='industry-background-pattern-image' src={section.backgroundpatternimage.sourceUrl} alt='background' onError={(e) => { e.target.style.display = 'none'; /* or any other error handling action */ }} />
        </>
      )}
    </section>
  );
};

export default IndustryImageDescription;
