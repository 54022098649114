import React, {useState, useEffect, useRef} from 'react'

function IndustryCardPoint ({ section }) {

    const [cardPointHover, setCardPointHover] = useState(null);
    const cardPointsContainerRef = useRef(null); // Ref for the card points container
  
    // Event handler for closing card point when clicking outside
    useEffect(() => {
      function handleClickOutside(event) {
        if (cardPointsContainerRef.current && !cardPointsContainerRef.current.contains(event.target)) {
          setCardPointHover(null); // Close the card point
        }
      }
  
      // Add event listener when a card point is open
      if (cardPointHover !== null) {
        document.addEventListener("mousedown", handleClickOutside);
      }
  
      // Cleanup event listener
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [cardPointHover]); // Re-run when cardPointHover changes

  return (
    <section className="industry-card-section-wrapper">
        <div className="industry-card-header-desc-point-container">
            <div className="industry-card-header-description-container">
                <h2 className="industry-card-header">{section.cardheader}</h2>
                <div dangerouslySetInnerHTML={{__html: section.carddescription}} className="industry-card-description"></div>
            </div>
            <div ref={cardPointsContainerRef} className='industry-card-point-container'>
                {section.cardpoint?.map((cardpoint, index) => (
                <div>
                    <p
                        key={index} // Ensure each element has a unique key
                        onClick={() => setCardPointHover(index === cardPointHover ? null : index)} // Toggle open/close on click
                        className={`industry-card-point ${cardPointHover === index ? 'hovered' : ''}`}>
                        {cardPointHover === index ? cardpoint.cardpoint : cardpoint.cardpointtitle}
                        <img 
                        className='industry-card-point-icons' 
                        src={cardPointHover === index ? '/images/DarkClose.svg' : '/images/DarkPlus.svg'} 
                        alt={cardPointHover === index ? 'close icon' : 'open icon'} />
                    </p>
                </div>
                ))}
            </div>
        </div>
  </section>  )
}

export default IndustryCardPoint